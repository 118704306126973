import Program from "@/Core/Program";
import StringUtilities from "@/Core/Utilities/StringUtilities";

import "@/App/Augmentations";

const stringUtilities = new StringUtilities();
const container = document.getElementById("root");

const program = new Program(stringUtilities, container);
program.start();
