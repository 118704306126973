import type { AnyObject, Flags, Maybe, Message, Schema } from "yup";
import { StringSchema } from "yup";

declare module "yup" {
    interface StringSchema<
        TType extends Maybe<string> = string | undefined,
        TContext extends AnyObject = AnyObject,
        TDefault = undefined,
        TFlags extends Flags = "",
    > extends Schema<TType, TContext, TDefault, TFlags> {
        notInList(list?: string[], message?: Message<{ list?: string[] }>): this;
    }
}

const notInList = function (this: StringSchema, list?: string[], message?: Message<{ list?: string[] }>) {
    return this.test({
        name: "notInList",
        message: message ?? `\${path} is not in allowed values`,
        exclusive: true,
        test: (value: Maybe<string>) => {
            if (!value) {
                return true;
            }
            const isBlocked = list?.some((code) => code === value);

            return !isBlocked;
        },
    });
};

StringSchema.prototype.notInList = notInList;
