import valid from "card-validator";
import type { AnyObject, Flags, Maybe, Message, Schema } from "yup";
import { StringSchema } from "yup";

declare module "yup" {
    interface StringSchema<
        TType extends Maybe<string> = string | undefined,
        TContext extends AnyObject = AnyObject,
        TDefault = undefined,
        TFlags extends Flags = "",
    > extends Schema<TType, TContext, TDefault, TFlags> {
        cardExpirationDate(message?: Message<{ value?: string }>): this;
    }
}

const cardExpirationDate = function (this: StringSchema, message?: Message<{ cvv?: string }>) {
    return this.test({
        name: "expiration",
        message: message ?? `\${path} is invalid`,
        exclusive: true,
        test: (value: Maybe<string>) => {
            return valid.expirationDate(value, 10).isValid;
        },
    });
};

StringSchema.prototype.cardExpirationDate = cardExpirationDate;
