import type { AnyObject, Flags, Maybe, Message, Schema } from "yup";
import { StringSchema } from "yup";

declare module "yup" {
    interface StringSchema<
        TType extends Maybe<string> = string | undefined,
        TContext extends AnyObject = AnyObject,
        TDefault = undefined,
        TFlags extends Flags = "",
    > extends Schema<TType, TContext, TDefault, TFlags> {
        cardCvv(message?: Message<{ value?: string }>): this;
    }
}

const cardCvv = function (this: StringSchema<string>, message?: Message<{ cvv?: string }>) {
    return this.test({
        name: "cvv",
        message: message ?? `\${path} is invalid`,
        exclusive: true,
        test: (value: Maybe<string>) => {
            if (!value) {
                return false;
            }

            const isNumeric = /^\d+$/.test(value);

            return isNumeric && (value.toString().length === 3 || value.toString().length === 4);
        },
    });
};

StringSchema.prototype.cardCvv = cardCvv;
