import dayjs from "dayjs";
import type { AnyObject, Flags, Maybe, Message, Schema } from "yup";
import { StringSchema } from "yup";

import { dateFormatDefault, earliestValidDate } from "@/Core/Constants";

declare module "yup" {
    interface StringSchema<
        TType extends Maybe<string> = string | undefined,
        TContext extends AnyObject = AnyObject,
        TDefault = undefined,
        TFlags extends Flags = "",
    > extends Schema<TType, TContext, TDefault, TFlags> {
        validDate(message?: Message<{ minimumAge: number }>): this;
    }
}

const validDate = function (this: StringSchema, message?: Message<{ minimumAge: number }>) {
    return this.test({
        name: "validDate",
        message: message ?? `\${path} is invalid date`,
        exclusive: true,
        test: (value: Maybe<string>) => {
            const dateHundredYearsAgo = dayjs(earliestValidDate);
            const dateValue = dayjs(value, dateFormatDefault);

            return !value ? true : dateValue.isValid() && dateValue >= dateHundredYearsAgo;
        },
    });
};

StringSchema.prototype.validDate = validDate;
