import valid from "card-validator";
import type { AnyObject, Flags, Maybe, Message, Schema } from "yup";
import { StringSchema } from "yup";

declare module "yup" {
    interface StringSchema<
        TType extends Maybe<string> = string | undefined,
        TContext extends AnyObject = AnyObject,
        TDefault = undefined,
        TFlags extends Flags = "",
    > extends Schema<TType, TContext, TDefault, TFlags> {
        cardNumber(message?: Message<{ value?: string }>): this;
    }
}

const cardNumber = function (this: StringSchema, message?: Message<{ cvv?: string }>) {
    return this.test({
        name: "cardNumber",
        message: message ?? `Please check your \${path} and try again`,
        exclusive: true,
        test: (value: Maybe<string>) => {
            return valid.number(value).isValid;
        },
    });
};

StringSchema.prototype.cardNumber = cardNumber;
