import type { AnyObject, Flags, Maybe, Message, Schema } from "yup";
import { StringSchema } from "yup";

declare module "yup" {
    interface StringSchema<
        TType extends Maybe<string> = string | undefined,
        TContext extends AnyObject = AnyObject,
        TDefault = undefined,
        TFlags extends Flags = "",
    > extends Schema<TType, TContext, TDefault, TFlags> {
        validYear(message?: Message<{ year: number }>): this;
    }
}

const validYear = function (this: StringSchema, message?: Message<{ year: number }>) {
    return this.test({
        name: "validYear",
        message: message ?? `Please provide a valid numeric \${path} (from 1900 onwards)`,
        exclusive: true,
        test: (value: Maybe<string>) => {
            if (value && isNaN(+value)) {
                return false;
            }
            if (Number(value) < 1900) {
                return false;
            }

            return true;
        },
    });
};

StringSchema.prototype.validYear = validYear;
